import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout title="404: Not found" showBreadcrumbs={false}>
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
